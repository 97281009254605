import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const appElement = document.getElementById('app');
    if (appElement) {
      appElement.scrollTo(0, 0);
      // appElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pathname]);

  return null;
}

export default ScrollToTop;
